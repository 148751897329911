<template>
    <footer class="bg-red-800 text-white p-4 sticky bottom-0 z-50">
        <nav class="flex flex-row justify-between">
           <a href="https://apps.apple.com/us/app/contemplative-rosary/id1477821831#?platform=ipad">
           <svg id="rosary-small" xmlns="http://www.w3.org/2000/svg" class="h-10 w-10" viewBox="0 0 59.051 61.412">
  <path id="Path_71" data-name="Path 71" d="M41.793,246.1l3.066,1.765a.083.083,0,0,1,.031.112l-.58,1.013a.081.081,0,0,1-.112.029l-3.071-1.765a.083.083,0,0,0-.112.031L37.136,254a.083.083,0,0,1-.112.031L36,253.441a.081.081,0,0,1-.029-.112l3.873-6.7a.081.081,0,0,0-.031-.112l-3.066-1.791a.081.081,0,0,1-.031-.112L37.3,243.6a.081.081,0,0,1,.112-.029l3.078,1.779a.086.086,0,0,0,.112-.031l1.974-3.447a.083.083,0,0,1,.112-.031l1.023.59a.081.081,0,0,1,.029.112l-1.974,3.442a.081.081,0,0,0,.029.119Z" transform="translate(-32.804 -192.629)" fill="#fff"/>
  <circle id="Ellipse_19" data-name="Ellipse 19" cx="2.422" cy="2.422" r="2.422" transform="translate(9.584 44.149)" fill="#fff"/>
  <circle id="Ellipse_20" data-name="Ellipse 20" cx="2.422" cy="2.422" r="2.422" transform="translate(18.877 30.126)" fill="#fff"/>
  <circle id="Ellipse_21" data-name="Ellipse 21" cx="2.422" cy="2.422" r="2.422" transform="translate(30.402 30.312)" fill="#fff"/>
  <circle id="Ellipse_21-2" data-name="Ellipse 21" cx="2.422" cy="2.422" r="2.422" transform="translate(24.693 29.122)" fill="#fff"/>
  <circle id="Ellipse_22" data-name="Ellipse 22" cx="2.422" cy="2.422" r="2.422" transform="translate(35.267 33.404)" fill="#fff"/>
  <circle id="Ellipse_23" data-name="Ellipse 23" cx="2.422" cy="2.422" r="2.422" transform="translate(39.893 37.189)" fill="#fff"/>
  <circle id="Ellipse_24" data-name="Ellipse 24" cx="2.422" cy="2.422" r="2.422" transform="translate(45.407 38.811)" fill="#fff"/>
  <circle id="Ellipse_24-2" data-name="Ellipse 24" cx="2.422" cy="2.422" r="2.422" transform="translate(50.402 36.432)" fill="#fff"/>
  <circle id="Ellipse_24-3" data-name="Ellipse 24" cx="2.422" cy="2.422" r="2.422" transform="translate(53.732 31.913)" fill="#fff"/>
  <circle id="Ellipse_24-4" data-name="Ellipse 24" cx="2.422" cy="2.422" r="2.422" transform="translate(54.208 26.204)" fill="#fff"/>
  <circle id="Ellipse_24-5" data-name="Ellipse 24" cx="2.422" cy="2.422" r="2.422" transform="translate(51.829 20.971)" fill="#fff"/>
  <circle id="Ellipse_25" data-name="Ellipse 25" cx="2.422" cy="2.422" r="2.422" transform="translate(10.771 27.719)" fill="#fff"/>
  <circle id="Ellipse_26" data-name="Ellipse 26" cx="2.422" cy="2.422" r="2.422" transform="translate(7.246 23.33)" fill="#fff"/>
  <circle id="Ellipse_27" data-name="Ellipse 27" cx="2.422" cy="2.422" r="2.422" transform="translate(2.985 19.417)" fill="#fff"/>
  <circle id="Ellipse_28" data-name="Ellipse 28" cx="2.422" cy="2.422" r="2.422" transform="translate(0.174 14.315)" fill="#fff"/>
  <circle id="Ellipse_29" data-name="Ellipse 29" cx="2.422" cy="2.422" r="2.422" transform="translate(34.967 6.422)" fill="#fff"/>
  <circle id="Ellipse_29-2" data-name="Ellipse 29" cx="2.422" cy="2.422" r="2.422" transform="translate(29.972 4.044)" fill="#fff"/>
  <circle id="Ellipse_29-3" data-name="Ellipse 29" cx="2.422" cy="2.422" r="2.422" transform="translate(39.962 9.277)" fill="#fff"/>
  <circle id="Ellipse_29-4" data-name="Ellipse 29" cx="2.422" cy="2.422" r="2.422" transform="translate(44.482 12.607)" fill="#fff"/>
  <circle id="Ellipse_29-5" data-name="Ellipse 29" cx="2.422" cy="2.422" r="2.422" transform="translate(48.288 16.651)" fill="#fff"/>
  <circle id="Ellipse_29-6" data-name="Ellipse 29" cx="2.422" cy="2.422" r="2.422" transform="translate(24.501 2.141)" fill="#fff"/>
  <circle id="Ellipse_29-7" data-name="Ellipse 29" cx="2.422" cy="2.422" r="2.422" transform="translate(18.792 0.714)" fill="#fff"/>
  <circle id="Ellipse_29-8" data-name="Ellipse 29" cx="2.422" cy="2.422" r="2.422" transform="translate(13.321 0)" fill="#fff"/>
  <circle id="Ellipse_29-9" data-name="Ellipse 29" cx="2.422" cy="2.422" r="2.422" transform="translate(7.612 0.476)" fill="#fff"/>
  <circle id="Ellipse_29-10" data-name="Ellipse 29" cx="2.422" cy="2.422" r="2.422" transform="translate(2.379 3.092)" fill="#fff"/>
  <circle id="Ellipse_29-11" data-name="Ellipse 29" cx="2.422" cy="2.422" r="2.422" transform="translate(0 8.563)" fill="#fff"/>
  <circle id="Ellipse_30" data-name="Ellipse 30" cx="2.422" cy="2.422" r="2.422" transform="translate(12.048 38.592)" fill="#fff"/>
  <circle id="Ellipse_31" data-name="Ellipse 31" cx="2.422" cy="2.422" r="2.422" transform="translate(13.561 33.035)" fill="#fff"/>
</svg>

           <span class="sr-only">Contemplative Rosary</span></a>
           
           <router-link :to="{ name: 'Examen'}">
               <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
               <span class="sr-only">Examen</span>
            </router-link>
           
          
          
           <router-link :to="{ name: 'Dashboard'}">
               <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                </svg>
                <span class="sr-only">Dashboard</span>
            </router-link>
           
            <router-link :to="{ name: 'Plan of Love'}">
               <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4" />
                </svg>
               <span class="sr-only">Plan of Love</span>
            </router-link>
           
           <button type="button" @click="openSettings">
               <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                </svg>
               <span class="sr-only">Settings</span>
            </button>
           
        </nav>
    </footer>
</template>

<script>
    export default {
        events: ['openSettingsMenu'],
        methods: {
            openSettings(){
                this.$emit("openSettingsMenu")
            }
        }
    }
</script>