<template>
    <header-component>
       <template #leftcontrol>
            <router-link :to="{name:'Prayers'}">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
                </svg>
            </router-link>
        </template>
        <template #header>{{name}}</template>
        <template #control>
            <div class="w-6 h-6"></div>
        </template>
    </header-component>
    <main-content>
        <div class="flex justify-center mb-8">
            <button type="button" class="p-4 text-white" :class="english ? 'bg-red-800' : 'bg-gray-300'" @click="english = true">English</button>
            <button type="button" class="p-4 text-white" :class="!english ? 'bg-red-800' : 'bg-gray-300'" @click="english = false">Latin</button>
        </div>
        <div class="text-2xl" v-if="name == 'Our Father'">
            <div v-if="english">
                <p>
                Our Father,<br />
                who art in heaven,<br />
                hallowed be thy name;<br />
                thy kingdom come;<br />
                thy will be done<br />
                on earth as it is in heaven.<br />
                Give us this day our daily bread;<br />
                and forgive us our trespasses<br />
                as we forgive those who trespass against us;<br />
                and lead us not into temptation,<br />
                but deliver us from evil.<br />
                <br />
                Amen.
                </p>
            </div>
            <div v-else>
                <p>
                Pater Noster,<br />
                qui es in caelis,<br /> 
                sanctificetur nomen tuum. Adveniat regnum tuum.<br />
                Fiat voluntas tua,<br />
                sicut in caelo et in terra.<br /> 
                Panem nostrum quotidianum da nobis hodie,<br />
                et dimitte nobis debita nostra sicut et nos dimittimus debitoribus nostris.<br />
                Et ne nos inducas in tentationem, sed libera nos a malo.<br />
                <br />
                Amen.
                </p>
            </div>
        </div>

        <div class="text-2xl" v-if="name == 'Hail Mary'">
            <div v-if="english">
                <p>
                Hail Mary,<br />
                Full of Grace,<br /> 
                The Lord is with thee.<br /> 
                Blessed art thou among women,<br /> 
                and blessed is the fruit<br />
                of thy womb, Jesus.<br /> 
                Holy Mary,<br /> 
                Mother of God,<br /> 
                pray for us sinners now,<br /> 
                and at the hour of our death.<br />
                <br />
                Amen.
                </p>
            </div>
            <div v-else>
                <p>
                Ave Maria,<br />
                gratia plena,<br /> 
                Dominus tecum.<br /> 
                Benedicta tu in mulieribus,<br />
                et benedictus fructus<br /> 
                ventris tui, Iesus.<br />
                Sancta Maria,<br /> 
                Mater Dei,<br /> 
                ora pro nobis peccatoribus,<br />
                nunc, et in hora mortis nostrae.<br />
                <br />
                Amen.
                </p>
            </div>
        </div>

        <div class="text-2xl" v-if="name == 'Glory Be'">
            <div v-if="english">
                <p>
                Glory be to the Father,<br />
                and to the Son,<br />
                and to the Holy Spirit.<br />
                As it was in the beginning,<br />
                is now,<br />
                and ever shall be,<br />
                world without end.<br />
                <br />
                Amen.
                </p>
            </div>
            <div v-else>
                <p>
                Gloria Patri,<br />
                et Filio,<br />
                et Spiritui Sancto.<br /> 
                Sicut erat in principio,<br /> 
                et nunc,<br /> 
                et semper,<br /> 
                et in saecula saeculorum.<br />
                <br />
                Amen.
                </p>
            </div>
        </div>

        <div class="text-2xl" v-if="name == 'Angelus'">
            <div v-if="english">
                <p>
                <strong>V.</strong> The Angel of the Lord declared to Mary:<br />
                <strong>R.</strong> And she conceived of the Holy Spirit.<br />
                <br />
                Hail Mary, full of grace, the Lord is with thee; blessed art thou among women and blessed is the fruit of thy womb, Jesus. Holy Mary, Mother of God, pray for us sinners, now and at the hour of our death. Amen.<br />
                <br />
                <strong>V.</strong> Behold the handmaid of the Lord:<br />
                <strong>R.</strong> Be it done unto me according to Thy word.<br />
                <br />
                Hail Mary…
                <br />
                <br />
                <strong>V.</strong> And the Word was made Flesh: <strong>R.</strong> And dwelt among us.<br />
                <br />
                Hail Mary…
                <br />
                <br />
                <strong>V.</strong> Pray for us, O Holy Mother of God,<br />
                <strong>R.</strong> that we may be made worthy of the promises of Christ.<br />
                <br />
                <strong>V.</strong> Let us pray:<br />
                <br />
                Pour forth, we beseech Thee, O Lord, Thy grace into our hearts; that we, to whom the incarnation of Christ, Thy Son, was made known by the message of an angel, may by His Passion and Cross be brought to the glory of His Resurrection, through the same Christ Our Lord.<br />
                <br />
                Amen. 
                </p>
            </div>
            <div v-else>
                <p>
                <strong>V.</strong> Angelus Domini nuntiavit Mariae.<br />
                <strong>R.</strong> Et concepit de Spiritu Sancto.<br />
                <br />

                Ave Maria, gratia plena; Dominus tecum: benedicta tu in mulieribus, et benedictus fructus ventris tui Iesus. Sancta Maria, Mater Dei ora pro nobis peccatoribus, nunc et in hora mortis nostrae. Amen.<br />
                <br />
                <strong>V.</strong> Ecce ancilla Domini,<br />
                <strong>R.</strong> Fiat mihi secundum verbum tuum.<br />
                <br />
                Ave Maria, gratia plena; Dominus tecum: benedicta tu in mulieribus, et benedictus fructus ventris tui Iesus. Sancta Maria, Mater Dei ora pro nobis peccatoribus, nunc et in hora mortis nostrae. Amen.<br />
                <br />
                <strong>V.</strong> Et Verbum caro factum est,<br />
                <strong>R.</strong> Et habitavit in nobis.<br />
                <br />
                Ave Maria, gratia plena; Dominus tecum: benedicta tu in mulieribus, et benedictus fructus ventris tui Iesus. Sancta Maria, Mater Dei ora pro nobis peccatoribus, nunc et in hora mortis nostrae. Amen.<br />
                <br />
                <strong>V.</strong> Ora pro nobis, sancta Dei Genetrix,<br />
                <strong>R.</strong> Ut digni efficiamur promissionibus Christi.<br />
                <br />
                <strong>V.</strong> Oremus.<br /> 
                <br />
                Gratiam tuam, quaesumus, Domine, mentibus nostris infunde; ut qui, Angelo nuntiante, Christi Filii tui incarnationem cognovimus, per passionem eius et crucem ad resurrectionis gloriam perducamur. Per eumdem Christum Dominum nostrum.<br />
                <br />
                Amen.
                </p>
            </div>
        </div>

         <div class="text-2xl" v-if="name == 'Act of Contrition'">
            <div v-if="english">
                <p>
                O my God,<br />
                I am heartily sorry for having offended Thee,<br />
                and I detest all my sins,<br />
                because I dread the loss of heaven, and the pains of hell;<br /> 
                but most of all because they offend Thee, my God,<br />
                Who are all good and deserving of all my love.<br />
                I firmly resolve, with the help of Thy grace,<br /> 
                to confess my sins, to do penance,<br />
                and to amend my life.<br />
                <br />
                Amen.
                </p>
            </div>
            <div v-else>
                <p>
                Deus meus,<br />
                ex toto corde poenitet me omnium meorum peccatorum,<br />
                eaque detestor,<br />
                quia peccando,<br /> 
                non solum poenas a Te iuste statutas promeritus sum,<br /> 
                sed praesertim quia offendi Te, summum bonum,<br /> 
                ac dignum qui super omnia diligaris.<br /> 
                Ideo firmiter propono,<br />
                adiuvante gratia Tua,<br /> 
                de cetero me non peccaturum peccandique occasiones proximas fugiturum.<br />
                <br />
                Amen.
                </p>
            </div>
        </div>

    </main-content>
</template>

<script>
export default {
    props: ['name'],
    data(){
        return {
            english: true
        }
    }
  
        
    }
</script>