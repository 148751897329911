<template>
<header class="bg-red-800 p-4 text-white text-center sticky top-0 z-50 flex justify-between items-center">
    <slot name="leftcontrol"></slot>
    <h1 class="text-3xl"><slot name="header"></slot></h1>
    <div><slot name="control"></slot></div>
</header>

</template>

<script>
    export default {
        
    }
</script>