<template>
<div class="flex flex-col justify-start">
    <div class="flex flex-row w-full justify-start font-bold text-xl mb-2">
        {{discipline}}
    </div>
    <div class="flex flex-row w-full space-x-2">
        <div class="flex flex-col justify-items-center items-center">
            <status-selector @open-status-modal="openStatusModal" :status="sun.status" :date="sun.date" :id="sun.id" day="Sun" :discipline="discipline"/>
        </div>
        <div class="flex flex-col justify-items-center items-center">
            <status-selector @open-status-modal="openStatusModal" :status="mon.status" :date="mon.date" :id="mon.id" day="Mon" :discipline="discipline"/>
        </div>
         <div class="flex flex-col justify-items-center items-center">
            <status-selector @open-status-modal="openStatusModal" :status="tues.status" :date="tues.date" :id="tues.id" day="Tues" :discipline="discipline"/>
        </div>
        <div class="flex flex-col justify-items-center items-center">
            <status-selector @open-status-modal="openStatusModal" :status="wed.status" :date="wed.date" :id="wed.id" day="Weds" :discipline="discipline"/>
        </div>
        <div class="flex flex-col justify-items-center items-center">
            <status-selector @open-status-modal="openStatusModal" :status="thurs.status" :date="thurs.date" :id="thurs.id" day="Thurs" :discipline="discipline"/>
        </div>
         <div class="flex flex-col justify-items-center items-center">
            <status-selector @open-status-modal="openStatusModal" :status="fri.status" :date="fri.date" :id="fri.id" day="Fri" :discipline="discipline"/>
        </div>
         <div class="flex flex-col justify-items-center items-center">
            <status-selector @open-status-modal="openStatusModal" :status="sat.status" :date="sat.date" :id="sat.id" day="Sat" :discipline="discipline"/>
        </div>
        
    </div>
</div>
</template>

<script>
import StatusSelector from './StatusSelector'
    export default {
        props: ['week', 'discipline'],
        components: {StatusSelector},
        events: ['openStatusModal'],
        methods: {
            openStatusModal(data){
                this.$emit('openStatusModal', data)
            }
        },
        computed: {
            sun(){
                let report = this.week.filter(function (e) {
                    var dt = new Date(e.date)
                    return dt.getDay() == 0
                })
                return report[0]
            },
            mon(){
                let report = this.week.filter(function (e) {
                    var dt = new Date(e.date)
                    return dt.getDay() == 1
                })
                return report[0]
            },
            tues(){
                let report = this.week.filter(function (e) {
                    var dt = new Date(e.date)
                    return dt.getDay() == 2
                })
                return report[0]
            },
            wed(){
                let report = this.week.filter(function (e) {
                    var dt = new Date(e.date)
                    return dt.getDay() == 3
                })
                return report[0]
            },
            thurs(){
                let report = this.week.filter(function (e) {
                    var dt = new Date(e.date)
                    return dt.getDay() == 4
                })
                return report[0]
            },
            fri(){
                let report = this.week.filter(function (e) {
                    var dt = new Date(e.date)
                    return dt.getDay() == 5
                })
                return report[0]
            },
            sat(){
                let report = this.week.filter(function (e) {
                    var dt = new Date(e.date)
                    return dt.getDay() == 6
                })
                return report[0]
            }
        }
    
    }
</script>