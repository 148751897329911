<template>
    <header-component>
        <template #leftcontrol>
            <router-link :to="{name:'Plan of Love'}">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
                </svg>
            </router-link>
        </template>
        <template #header>VirtueShare</template>
        <template #control>
            <div class="h-6 w-6"></div>
        </template>
    </header-component>
    <main-content>
        <div class="flex flex-col justify-between items-center h-full">
           <p>WIP - view/manage partners</p>
        </div>
    </main-content>
</template>

<script>
    export default {

        
    }
</script>