import { createRouter, createWebHistory } from 'vue-router'
import Dashboard from '../views/Dashboard.vue'
import Plan from '../views/Plan.vue'
import EditPlan from '../views/EditPlan.vue'
import VirtueShare from '../views/VirtueShare.vue'
import Partners from '../views/Partners.vue'
import ManagePartner from '../views/ManagePartner.vue'
import Partner from '../views/Partner.vue'
import Rosary from '../views/Rosary.vue'
import Prayers from '../views/Prayers.vue'
import SpiritualDirection from '../views/SpiritualDirection.vue'
import Examen from '../views/Examen.vue'
import ExamenAlert from '../views/ExamenAlert.vue'
import Prayer from '../views/Prayer.vue'

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    props: true
  },
  {
    path: '/plan',
    name: 'Plan of Love',
    component: Plan,
    props: true
  },
  {
    path: '/plan/edit',
    name: 'Edit Plan of Love',
    component: EditPlan,
    props: true
  },
  {
    path: '/examen',
    name: 'Examen',
    component: Examen,
    props: true
  },
  {
    path: '/examen/alert',
    name: 'Examen Alert',
    component: ExamenAlert,
    props: true
  },
  {
    path: '/prayers',
    name: 'Prayers',
    component: Prayers,
    props: true,
  },
      {
        path: '/prayers/our-father',
        name: 'Our Father',
        component: Prayer,
        props: {name: 'Our Father'}
      },
      {
        path: '/prayers/hail-mary',
        name: 'Hail Mary',
        component: Prayer,
        props: {name: 'Hail Mary'}
      },
      {
        path: '/prayers/glory-be',
        name: 'Glory Be',
        component: Prayer,
        props: {name: 'Glory Be'}
      },
      {
        path: '/prayers/angelus',
        name: 'Angelus',
        component: Prayer,
        props: {name: 'Angelus'}
      },
      {
        path: '/prayers/act-of-contrition',
        name: 'Act of Contrition',
        component: Prayer,
        props: {name: 'Act of Contrition'}
      },
  {
    path: '/partner/:id/manage',
    name: 'ManagePartner',
    component: ManagePartner,
    props: true
  },
  {
    path: '/partner/:id',
    name: 'Partner',
    component: Partner,
    props: true
  },
  {
    path: '/partners',
    name: 'Partners',
    component: Partners,
    props: true
  },
  {
    path: '/rosary',
    name: 'Rosary',
    component: Rosary,
    props: true
  },
  {
    path: '/spiritual-direction',
    name: 'Spiritual Direction',
    component: SpiritualDirection,
    props: true
  },
  {
    path: '/virtueshare',
    name: 'VirtueShare',
    component: VirtueShare,
    props: true
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
