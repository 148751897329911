<template>
    <header-component>
        <template #leftcontrol><div class="h-6 w-6"></div></template>
        <template #header>Daily Examen</template>
        <template #control> <router-link :to="{ name: 'Examen Alert'}">
               <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
               <span class="sr-only">Examen Alert</span>
            </router-link>
        </template>
    </header-component>
    <main-content>
        <p class="mb-4">1. Before reflecting on your plan of love or commitments, sit a moment in silence, preferably before Mass, during adoration or before night prayer. Recognize and acknowledge the loving gaze of the Blessed Trinity upon you. Make the sign of the cross and pray this prayer or something similar.</p>

        <p class="mb-4"><strong><em>Dear Lord, You know my heart and my soul better than I know myself. You know my sin, my imperfections and those things which keep me from living in the fullness of love for You and those You have placed in my care. Show me my sins, my imperfections and how I have honored and failed You. I beg You to help me to rejoice in the successes that have come by Your grace and to turn from choices I have made that reveal sin and selfishness within me. Please help me now as I examine my actions to see what You see and to express my humble gratitude for successes that You have granted by Your grace and to seek forgiveness for times when I have failed to give all that I am to You.</em></strong></p>

        <p class="mb-4"><strong><em>Help me to humbly acknowledge my failures and rest in Your redemptive provision knowing that without You, I can accomplish nothing, but with You, I can overcome these failures to Your glory and continue to embrace Your virtues by Your grace. Your love is worthy of all my love. Help me to return that love with joy and to see You and to follow You in all I think, say, and do.</em></strong></p>

        <p class="mb-4">2. Review your plan of love slowly and silently – indicating where you have seen progress and need to make better progress. Listen to any promptings of love and direction. Each time you mark a section as “Fulfilled - F” give praise to God. You might offer praise like, <strong><em>“Thank You Lord Jesus for your grace.”</em></strong></p>

        <p class="mb-4">3) For those areas where you have failed, mark the section as “Partially Fulfilled - PF” or “Not Fulfilled - NF.” Recognize that this failure is a part of the cross and a realization of your need for God to complete the work of self-sacrifice that you failed to complete. Ask for forgiveness as a child when a loving parent looks on them with joy because the heart of the child is rightly oriented to the truth and desires to please them. Say something like, <strong><em>“I am sorry Lord, please help me to better serve you.”</em></strong> You can also use the traditional act of contrition from the Mass here. Accept the Lord’s love and forgiveness. Reject any negative self-talk, self-focus or self-condemnation that focuses on you rather than God’s redemptive work in your soul.</p>

        <p class="mb-4">4) Take a moment and list three things for which you are especially grateful.c</p>

        <p class="mb-4">5) Conclude your time with thanksgiving for progress made and a commitment to correct your failures with the Lord’s help. Make the sign of the cross to conclude your prayer acknowledging His profound love for you in His sacrifice on your behalf and His commitment to draw you to Himself through both your failures and successes.</p>
    </main-content>
</template>

<script>
    export default {
        
    }
</script>