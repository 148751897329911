<template>
    <div class="mt-40 absolute w-screen bg-red-800 shadow-2xl z-50 transition-height p-4 text-white" :class="menuStatus ? 'h-screen' : 'h-0'">
       <div class="flex w-full justify-center mb-4">
           <button type="button" @click="closeSettingsMenu">
             <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
            </svg>
            <span class="sr-only">Close</span>
           </button>
       </div>
       <div class="flex justify-between w-full">
           <div class="p-2 flex">
            <img class="inline object-cover w-16 h-16 mr-2 rounded-full" src="https://images.pexels.com/photos/2589653/pexels-photo-2589653.jpeg?auto=compress&cs=tinysrgb&h=650&w=940" alt="Profile image"/>
                <div class="flex flex-col">
                    <span class="font-bold text-xl">Sample Person</span>
                    <span>CHAZ, Portland</span>
                </div> 
           </div>
           <button type="button" @click="editProfile">
               <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                </svg>
            <span class="sr-only">Edit Profile</span>
           </button>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['menuStatus'],
        methods: {
            closeSettingsMenu(){
                this.$emit("closeSettingsMenu")
            }
        }
    }
</script>