<template>
    <button type="button" @click="openStatusModal" class="">
         <div class="w-12 h-12 rounded-full flex justify-center items-center" :class="statusColor">
                <span class="font-bold text-sm">{{day}}</span>
         </div>
    </button>
</template>

<script>
    export default {
        props: ['status', 'id', 'date', 'day', 'discipline'],
        events: ['openStatusModal'],
        methods: {
            openStatusModal(){
                this.$emit('openStatusModal', {id: this.id, date: this.date, day: this.day, discipline: this.discipline})
            }
        },
        computed: {
            statusDisplay() {
                let buttonText = '';
                switch(this.status) {
                    case 0:  
                        buttonText = 'NF'
                        break;
                    case 1:
                        buttonText = 'PF'
                        break;
                    case 2: 
                        buttonText = 'F'
                        break                
                }
                return buttonText
            },
         statusColor() {
                let buttonColor = '';
                switch(this.status) {
                    case 0:  
                        buttonColor = 'bg-red-500'
                        break;
                    case 1:
                        buttonColor = 'bg-yellow-500'
                        break;
                    case 2: 
                        buttonColor = 'bg-green-500'
                        break
                    case 3: 
                        buttonColor = 'bg-gray-500'
                        break               
                }
                return buttonColor
            }

        }
        
    }
</script>