<template>
    <settings-menu v-show="settingsOpen" @close-settings-menu="onCloseSettingsMenu" :menuStatus="settingsOpen"/>
  <div class="flex flex-col h-screen">
    <router-view />
    <footer-menu @open-settings-menu="onOpenSettingsMenu" />
  </div>
</template>

<script>
import FooterMenu from './components/FooterMenu'
import SettingsMenu from './components/SettingsMenu.vue'

export default {
  components: {FooterMenu, SettingsMenu},
  data() {
    return {
      settingsOpen: false,
    }
  },
  methods: {
    onOpenSettingsMenu(){
      this.settingsOpen = !this.settingsOpen
    },
    onCloseSettingsMenu(){
      this.settingsOpen = false
    }
  }
}
</script>