<template>
    <div class="mt-10 absolute w-screen bg-gray-400 shadow-2xl z-50 transition-height p-4 text-white" :class="modalStatus ? 'h-screen' : 'h-0'">
       <div class="flex w-full justify-center mb-4">
           <button type="button" @click="closeStatusModal">
             <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
            </svg>
            <span class="sr-only">Close</span>
           </button>
       </div>
       <div class="flex justify-center w-full mb-8">
        <h2 class="font-bold text-xl">{{discipline}} - {{day}}, {{date}}</h2>
        </div>
       <div class="flex justify-center space-x-4 w-full mb-8">
           <button type="button" @click="changeStatus(2)">
                <div class="w-12 h-12 rounded-full flex justify-center items-center bg-green-500">
                    <span class="font-bold text-sm">F</span>
                </div>
            </button>
            <button type="button" @click="changeStatus(1)">
                <div class="w-12 h-12 rounded-full flex justify-center items-center bg-yellow-500">
                    <span class="font-bold text-sm">PF</span>
                </div>
            </button>
            <button type="button" @click="changeStatus(0)">
                <div class="w-12 h-12 rounded-full flex justify-center items-center bg-red-500">
                    <span class="font-bold text-sm">NF</span>
                </div>
            </button>
            <button type="button" @click="changeStatus(3)">
                <div class="w-12 h-12 rounded-full flex justify-center items-center bg-gray-500">
                    <span class="font-bold text-sm">NA</span>
                </div>
            </button>
        </div>
        <div class="w-full flex flex-col">
            <label for="note">Notes:</label>
            <textarea class="h-48" v-model="note"></textarea>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['modalStatus', 'date', 'day', 'discipline', 'id'],
        events: ['closeStatusModal', 'changeStatus'],
        data() {
            return {
                note: '',
            }
        },
        methods: {
            closeStatusModal(){
                this.$emit("closeStatusModal")
            },
            changeStatus(status){
                this.$emit("changeStatus", {id: this.id, status, note: this.note})
            }
        }
    }
</script>