<template>
    <header-component>
        <template #leftcontrol>
            <div class="w-6 h-6"></div>
        </template>
        <template #header>Other Prayers</template>
        <template #control>
            <div class="w-6 h-6"></div>
        </template>
    </header-component>
    <main-content>
        <div class="flex flex-col justify-start items-center h-full text-center text-2xl">
            <router-link class="p-4 w-full" :to="{name:'Our Father'}">Our Father</router-link>
            <router-link class="p-4 w-full bg-gray-300" :to="{name:'Hail Mary'}">Hail Mary</router-link>
            <router-link class="p-4 w-full" :to="{name:'Glory Be'}">Glory Be</router-link>
            <router-link class="p-4 w-full bg-gray-300" :to="{name:'Angelus'}">Angelus</router-link>
            <router-link class="p-4 w-full" :to="{name:'Act of Contrition'}">Act of Contrition</router-link>
        </div>
    </main-content>
</template>

<script>
    export default {
        
    }
</script>