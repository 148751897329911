import { createApp } from 'vue'
import App from './App.vue'
import './assets/tailwind.css'
import router from './router'
import HeaderComponent from './components/HeaderComponent'
import MainContent from './components/MainContent'
import VueTimepicker from 'vue3-timepicker'
import 'vue3-timepicker/dist/VueTimepicker.css'


const app = createApp(App)
app.component('HeaderComponent', HeaderComponent)
app.component('MainContent', MainContent)
app.component('VueTimepicker', VueTimepicker)
app.use(router).mount('#app')
